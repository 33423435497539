.carousel-track {
  animation: slide 60s linear infinite;

  @media (max-width: 961px) {
    animation: slide 60s linear infinite;
  }
}

.carousel-track:hover {
  animation-play-state: paused;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}