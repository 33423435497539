.flippable-card {
  background-color: white;
  transform-style: preserve-3d;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
  transition: transform 1500ms;
}

.flippable-card .card-front,
.flippable-card .card-back {
  line-height: 18px;
  backface-visibility: hidden;
}

.flippable-card .card-back {
  transform: rotateY(180deg) rotateZ(180deg);
}

.flippable-card.flipped {
  transform: rotateY(180deg) rotateZ(180deg);
}
