@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&family=Unbounded:wght@200;300;400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow: hidden;
  height: 100%;
}

body {
  position: relative;
  overflow: auto;
  height: 100%;
  margin: 0;
  @apply font-body;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-bg-default;
  overflow-x: hidden;
}

@layer utilities {
  .perspective {
    perspective: 1000px;
  }
}

/* button, input {
  @apply focus:ring-0 focus:ring-offset-0;
} */

#page {
  max-width: 992px;
  margin: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-title;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: none !important;
  margin: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  /*margin: 2.3rem;*/
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
}

.card .card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: left;
  margin-bottom: 1rem;
  @apply text-xl;
}
