.hero-schools #arrow-down-twirl {
    animation-name: hero-schools-arrow-bump;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
}

@keyframes hero-schools-arrow-bump {
    0% {
        top: 5rem;
    }
    50% {
        top: 4.75rem;
    }
    100% {
        top: 5rem;
    }
}